// src/components/ForgotPassword.js

import React, { useState, useContext } from 'react';
import {Container, Row, Col, Form, Alert, Spinner, Button, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Added import
import { AuthContext } from '../context/AuthContext';
import AuthCard from './AuthCard';

const ForgotPassword = () => {
    const { forgotPassword } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors([]);
        setMessage('');

        try {
            const res = await forgotPassword(email);
            setMessage(res.message); // Access res.message directly
        } catch (err) {
            if (err.response && err.response.data) {
                setErrors([{ msg: err.response.data.error }]);
            } else {
                setErrors([{ msg: 'Something went wrong. Please try again.' }]);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid className="app-container">
            <Row className="justify-content-center">
                <Col xs={12} md={6} lg={4}>
                    <AuthCard>
                        <h3 className="text-center mb-4">Salasõna taastamine</h3>
                        <Card.Text className="mb-4">
                            Juhul kui unustasite enda salasõna saate selle taastada enda e-maili kaudu.
                        </Card.Text>
                        {errors.length > 0 && (
                            <Alert variant="danger">
                                {errors.map((error, idx) => (
                                    <div key={idx}>{error.msg}</div>
                                ))}
                            </Alert>
                        )}

                        {message && <Alert variant="success">{message}</Alert>}

                        <Form onSubmit={onSubmit}>
                            <Form.Group controlId="email" className="mb-3">
                                <Form.Label>E-mail, millega kasutaja on registreeritud</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Sisesta email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <div className="d-grid">
                                <Button type="submit" variant="secondary" className="back-button primary-button-2 align-items-center" disabled={loading}>
                                    {loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            Saadetakse...
                                        </>
                                    ) : (
                                        'Taasta salasõna'
                                    )}
                                </Button>
                            </div>
                        </Form>

                        <div className="text-center mt-3">
                            <span>Salasõna tuli meelde? </span>
                            <Link to="/login">Logi sisse</Link>
                        </div>
                    </AuthCard>
                </Col>
            </Row>
        </Container>
    );
};

export default ForgotPassword;
