// src/components/VerifyEmail.js

import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Container, Alert, Spinner } from 'react-bootstrap';
import axios from '../api';

const VerifyEmail = () => {
    const { verifyEmail } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [resendLoading, setResendLoading] = useState(false);
    const [resendMessage, setResendMessage] = useState('');

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        const id = query.get('id');

        if (token && id) {
            verifyEmail(token, id)
                .then((res) => {
                    setMessage(res.message);
                    // Redirect to login after a brief delay upon successful verification
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                })
                .catch((err) => {
                    setError(err.response?.data?.error || 'Kasutaja kinnitamine ei õnnestunud.');
                })
                .finally(() => setLoading(false));
        } else {
            setError('Vale kinnituse link.');
            setLoading(false);
        }
    }, [location, navigate, verifyEmail]);

    const handleResendVerification = async () => {
        setResendLoading(true);
        setResendMessage('');
        const email = new URLSearchParams(location.search).get('email'); // Assumes email is passed as query parameter

        try {
            await axios.post('/api/users/resend-verification', { email });
            setResendMessage(`Kinnituskiri saadeti uuesti aadressile ${email}.`);
        } catch (err) {
            setError(err.response?.data?.error || 'Midagi läks valesti. Palun proovige uuesti.');
        } finally {
            setResendLoading(false);
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center vh-100">
            <div className="text-center">
                {loading ? (
                    <Spinner animation="border" variant="primary" />
                ) : message ? (
                    <Alert variant="success">{message || 'E-maili kinnitus õnnestus! Teid suunatakse peagi logimislehele.'}</Alert>
                ) : (
                    <Alert variant="danger">
                        {error || 'Kasutaja kinnitamine ei õnnestunud.'}
                        <br />
                        <strong>
                            <a href="#resend" onClick={handleResendVerification} style={{ color: 'inherit', textDecoration: 'underline' }}>
                                {resendLoading ? 'Saadan...' : 'Saada kinnituskiri uuesti'}
                            </a>
                        </strong>
                    </Alert>
                )}

                {resendMessage && (
                    <Alert variant="success" className="mt-3">
                        {resendMessage}
                    </Alert>
                )}
            </div>
        </Container>
    );
};

export default VerifyEmail;
