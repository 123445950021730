// src/components/BuyUnemundid.js

import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { FaArrowLeft, FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from '../api';

const BuyUnemundid = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [credits, setCredits] = useState(0);
    const [creditsLoading, setCreditsLoading] = useState(true);
    const [creditsError, setCreditsError] = useState('');

    useEffect(() => {
        const fetchCredits = async () => {
            try {
                const response = await axios.get('/api/users/me');
                setCredits(response.data.credits);
            } catch (error) {
                console.error('Error fetching credits:', error);
                setCreditsError('Viga krediitide laadimisel.');
            } finally {
                setCreditsLoading(false);
            }
        };

        if (localStorage.getItem('token')) {
            fetchCredits();
        }
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleStripePayment = () => {
        if (!user) {
            alert('Palun logi sisse enne krediitide ostmist.');
            return;
        }

        const userId = user.id;
        const encodedUserId = encodeURIComponent(userId);
        const baseStripeLink = process.env.REACT_APP_STRIPE_BASE_LINK;
        const successUrl = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}/payment-success`);
        const stripeLink = `${baseStripeLink}?client_reference_id=${encodedUserId}&locale=et&success_url=${successUrl}`;

        window.open(stripeLink, '_blank');
    };

    return (
        <Container className="app-container">
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <div className="main-card">
                        <Card.Body>
                            {/* Display Unemündid Balance as Title with Shopping Cart Icon */}
                            <Card.Title className="mb-4 text-center d-flex align-items-center">
                                <FaShoppingCart className="me-2" />
                                {creditsLoading ? (
                                    <Spinner animation="border" variant="primary" />
                                ) : creditsError ? (
                                    <span className="text-danger">{creditsError}</span>
                                ) : (
                                    <span>Teil on praegu <b>{credits}</b> unemünti</span>
                                )}
                            </Card.Title>

                            <Card.Text className="mb-4">
                                Unemünte saate juurde soetada siit:<br/><br/>
                                <div className="d-grid gap-2 mb-3">
                                    <Button
                                        variant="secondary"
                                        className="primary-button-2 align-items-center"
                                        onClick={handleStripePayment}
                                    >
                                        Lisa kontole unemünte
                                    </Button>
                                </div>
                                Ühe unemündi maksumus on 0.10€ ning sellega saate genereerida ühe tavalise unejutu. Pikemate unejuttude jaoks kulub kaks unemünti.
                            </Card.Text>

                            <Card.Text className="mb-4">
                                Kasutame maksevahendajana Stripe keskkonda. Unemündid lisatakse Teie kontole kohe peale makse tegemist. Juhul kui eelistate tasuda pangaülekandega saatke oma tellimus soov e-mailile:
                                <a href="mailto:telli@unelaegas.ee" className="link"> <b>telli@unelaegas.ee</b></a>.<br/><br/>
                                Juhime tähelepanu, et pangaülekandega tasudes võtab unemüntide lisamine veidi kauem aega ning minimaalne unemüntide kogus on 100 tükki.<br/><br/>
                                <b>Makse saaja:</b><br/>
                                UQ OÜ<br/>
                                Reg kood: 14320814<br/>
                                E-mail: ver@uq-it.ee
                            </Card.Text>

                            <Button variant="secondary" className="back-button primary-button-2 align-items-center"
                                    onClick={handleBack} aria-label="Tagasi">
                                <FaArrowLeft className="me-2" /> Tagasi
                            </Button>
                        </Card.Body>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default BuyUnemundid;
