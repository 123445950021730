// src/components/PrivacyPolicy.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <Container className="privacy-container">
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <Card className="privacy-card text-center">
                        <Card.Body>
                            {/* Pealkiri */}
                            <h1 className="privacy-title">Privaatsuspoliitika</h1>

                            {/* Viimati uuendatud */}
                            <p className="privacy-update">
                                <strong>Viimati uuendatud:</strong> 8. novembril 2024
                            </p>

                            {/* Sissejuhatus */}
                            <h2 className="privacy-section-title">1. Sissejuhatus</h2>
                            <p className="privacy-section-text">
                                Tere tulemast meie rakendusse! Meie privaatsuspoliitika selgitab, kuidas me kogume, kasutame, jagame ja kaitseme teie isikuandmeid. Meie rakendus on loodud eesmärgiga pakkuda AI-põhiseid unejutte ning me pühendume teie privaatsuse kaitsmisele.
                            </p>

                            {/* Kogutavad andmed */}
                            <h2 className="privacy-section-title">2. Kogutavad andmed</h2>
                            <p className="privacy-section-text">
                                Me ei kogu teie isiklikke andmeid ilma teie nõusolekuta. Siiski võime koguda järgmisi andmeid:
                            </p>
                            <ul className="privacy-list">
                                <li><strong>Tehnilised andmed:</strong> Näiteks IP-aadress, brauseri tüüp ja seadme tüüp, mida kasutate meie rakenduse kasutamiseks.</li>
                                <li><strong>Kasutusandmed:</strong> Teie kasutustegevus rakenduses, nagu loodud lood ja valitud vanusevahemikud.</li>
                            </ul>

                            {/* Andmete kasutamine */}
                            <h2 className="privacy-section-title">3. Andmete kasutamine</h2>
                            <p className="privacy-section-text">
                                Kogutud andmeid kasutatakse järgmiste eesmärkide saavutamiseks:
                            </p>
                            <ul className="privacy-list">
                                <li>Rakenduse toimimine ja parendamine.</li>
                                <li>Kasutajakogemuse kohandamine vastavalt teie eelistustele.</li>
                                <li>Vastamine kasutajate päringutele ja tehniline tugi.</li>
                            </ul>

                            {/* Andmete jagamine */}
                            <h2 className="privacy-section-title">4. Andmete jagamine</h2>
                            <p className="privacy-section-text">
                                Me ei müü, ei vaheta ega edasta teie isiklikke andmeid kolmandatele isikutele ilma teie nõusolekuta, välja arvatud järgmistel juhtudel:
                            </p>
                            <ul className="privacy-list">
                                <li><strong>Seaduslikud Nõuded:</strong> Kui seadus nõuab, et me peame teie andmeid jagama.</li>
                                <li><strong>Õiguskaitse:</strong> Et kaitsta oma õigusi, vara või ohutust, samuti teiste kasutajate õigusi.</li>
                            </ul>

                            {/* Andmete kaitse */}
                            <h2 className="privacy-section-title">5. Andmete kaitse</h2>
                            <p className="privacy-section-text">
                                Me rakendame asjakohaseid turvameetmeid, et kaitsta teie andmeid volitamata juurdepääsu, muutmise või hävitamise eest. Siiski ei saa me garanteerida absoluutset turvalisust internetis.
                            </p>

                            {/* Küpsised ja jälgimistehnoloogiad */}
                            <h2 className="privacy-section-title">6. Küpsised ja jälgimistehnoloogiad</h2>
                            <p className="privacy-section-text">
                                Me kasutame ainult küpsiseid, mis on hädavajalikud rakenduse põhifunktsioonide toimimiseks. Need küpsised ei nõua teie aktiivset nõusolekut. Teie veebilehe kasutamise ajal võivad koguda tehnilisi andmeid, nagu IP-aadressid ja brauseri info, et parandada rakenduse toimivust ja kasutajakogemust.
                            </p>

                            {/* Teie õigused */}
                            <h2 className="privacy-section-title">7. Teie õigused</h2>
                            <p className="privacy-section-text">
                                Teil on õigus:
                            </p>
                            <ul className="privacy-list">
                                <li>Saada teavet oma andmete kohta.</li>
                                <li>Lõpetada või piirata oma andmete töötlemist.</li>
                                <li>Taotleda oma andmete parandamist või kustutamist.</li>
                                <li>Esitada kaebus andmekaitse järelevalveasutusele.</li>
                            </ul>

                            {/* Lapseealised kasutajad */}
                            <h2 className="privacy-section-title">8. Lapseealised kasutajad</h2>
                            <p className="privacy-section-text">
                                Meie rakendus on mõeldud kasutamiseks ka lastele. Kui te olete lapsevanem või hooldaja ja usute, et teie laps on andmeid jaganud ilma teie nõusolekuta, võtke meiega ühendust, et saaksime vajalikud meetmed võtta.
                            </p>

                            {/* Privaatsuspoliitika muudatused */}
                            <h2 className="privacy-section-title">9. Privaatsuspoliitika muudatused</h2>
                            <p className="privacy-section-text">
                                Võime aeg-ajalt oma privaatsuspoliitikat uuendada. Teavitame teid muudatustest, postitades uue privaatsuspoliitika meie rakendusse. Soovitame teil regulaarselt üle vaadata meie privaatsuspoliitikat, et olla kursis võimalike muudatustega.
                            </p>

                            {/* Andmete Säilitamine */}
                            <h2 className="privacy-section-title">10. Andmete säilitamine</h2>
                            <p className="privacy-section-text">
                                Kogutud andmeid säilitame nii kaua, kuni see on vajalik nende kogumise eesmärkide täitmiseks, välja arvatud juhul, kui seadus nõuab pikemat säilitusaega.
                            </p>

                            {/* Kolmandad Osapooled */}
                            <h2 className="privacy-section-title">11. Kolmandad osapooled</h2>
                            <p className="privacy-section-text">
                                Võib-olla jagame teatud andmeid kolmandate osapooltega, kes pakuvad meie rakendusele seotud teenuseid. Neid osapooli on lubatud kasutada teie andmeid ainult meie nimel ja vastavalt meie juhistele.
                            </p>

                            {/* Andmekaitse Juhtimine */}
                            <h2 className="privacy-section-title">12. Andmekaitse juhtimine</h2>
                            <p className="privacy-section-text">
                                UQ OÜ on andmekaitse juhi ametikohtadele määratud isik, kes vastutab teie andmete kaitse eest. Kui teil on küsimusi või muresid meie andmekaitse praktikate kohta, võtke ühendust:
                            </p>
                            <ul className="privacy-list">
                                <li>Email: <a href="mailto:ver@uq-it.ee" className="privacy-link">ver@uq-it.ee</a></li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PrivacyPolicy;
