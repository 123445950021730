// src/components/PastStories.js

import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../api';
import {
    Spinner,
    Alert,
    Card,
    Container,
    Row,
    Col,
    Button,
    Badge,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { FaArrowLeft, FaBook, FaExpandAlt, FaCompressAlt, FaSignOutAlt, FaShoppingCart, FaTrash } from 'react-icons/fa';

const PastStories = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [stories, setStories] = useState([]);
    const [credits, setCredits] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedStories, setExpandedStories] = useState({});
    const [creditsLoading, setCreditsLoading] = useState(true);
    const [creditsError, setCreditsError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await axios.get('/api/users/me', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setCredits(userResponse.data.credits);
            } catch (err) {
                console.error('Error fetching credits:', err);
                setCreditsError('Viga krediitide laadimisel.');
            } finally {
                setCreditsLoading(false);
            }

            try {
                const response = await axios.get('/api/stories?liked=true', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setStories(response.data.stories);
            } catch (err) {
                console.error('Error fetching stories:', err);
                setError('Failed to load stories. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (localStorage.getItem('token')) {
            fetchData();
        } else {
            setLoading(false);
            setError('You must be logged in to view past stories.');
        }
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleBack = () => {
        navigate('/generate-story');
    };

    const toggleExpand = (id) => {
        setExpandedStories((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleRemoveStory = async (storyId) => {
        try {
            await axios.post(`/api/stories/${storyId}/unsave`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setStories(stories.filter((story) => story.id !== storyId));
        } catch (error) {
            console.error('Error removing the story:', error);
            setError('Viga loo eemaldamisel.');
        }
    };

    const cleanTitle = (title) => {
        return title.replace(/Pealkiri:|\*|"|###/g, '').trim();
    };

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center loading-container">
                <Spinner animation="border" variant="primary" />
            </Container>
        );
    }

    return (
        <Container className="app-container">
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <div className="main-card">
                        <div className="d-flex justify-content-between align-items-center mb-4 navigation">
                            <Button
                                variant="secondary"
                                onClick={handleBack}
                                aria-label="Navigate back to Generate Story page"
                                className="back-button primary-button-2 align-items-center"
                            >
                                <FaArrowLeft className="me-2"/> Tagasi
                            </Button>
                        </div>

                        <h2 className="mb-4 text-center">Salvestatud jutud</h2>

                        {error && (
                            <Alert variant="danger" className="mb-4">
                                {error}
                            </Alert>
                        )}

                        {stories.length === 0 ? (
                            <Alert variant="info">Teil pole veel salvestatud lugusid.</Alert>
                        ) : (
                            <Row xs={1} className="g-4">
                                {stories.map((story) => (
                                    <Col key={story.id}>
                                        <Card className="story-card h-100">
                                            <Card.Header className="story-card-header d-flex align-items-center">
                                                <FaBook className="me-2" aria-label="Story icon" />
                                                {cleanTitle(story.title)}
                                            </Card.Header>
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Text className="story-content" id={`story-content-${story.id}`}>
                                                    {expandedStories[story.id]
                                                        ? <ReactMarkdown>{story.content}</ReactMarkdown>
                                                        : story.content.length > 200
                                                            ? <ReactMarkdown>{`${story.content.substring(0, 200)}...`}</ReactMarkdown>
                                                            : <ReactMarkdown>{story.content}</ReactMarkdown>}
                                                </Card.Text>
                                                {story.content.length > 200 && (
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Button
                                                            variant="link"
                                                            className="past-stories-button"
                                                            onClick={() => toggleExpand(story.id)}
                                                            aria-expanded={expandedStories[story.id] ? 'true' : 'false'}
                                                            aria-controls={`story-content-${story.id}`}
                                                            style={{ textDecoration: 'none' }}
                                                        >
                                                            {expandedStories[story.id] ? (
                                                                <>
                                                                    <FaCompressAlt className="me-1" /> Näita vähem
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaExpandAlt className="me-1" /> Loe rohkem
                                                                </>
                                                            )}
                                                        </Button>
                                                        {expandedStories[story.id] && (
                                                            <FaTrash
                                                                className="text-danger"
                                                                role="button"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => handleRemoveStory(story.id)}
                                                                aria-label="Eemalda lugu"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PastStories;
