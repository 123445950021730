// src/api.js

import axios from 'axios';

// Check if localStorage is available, otherwise use sessionStorage
const storage = window.localStorage || window.sessionStorage;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Ensure this is set in your .env file
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to attach token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = storage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config._isAuthenticated = true; // Mark the request as authenticated
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest._isAuthenticated
    ) {
      // Unauthorized, token might be expired
      // Remove token and redirect to login
      storage.removeItem('token');
      window.location.href = '/login?error=session_expired';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
