// src/components/Login.js

import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import axios from '../api'; // Use the axios instance
import { AuthContext } from '../context/AuthContext';
import { useNavigate, Link, Navigate, useLocation } from 'react-router-dom';
import AuthCard from './AuthCard';
import PrimaryButton from './PrimaryButton';

const Login = () => {
  const { login, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendMessage, setResendMessage] = useState('');

  const { email, password } = formData;

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const error = query.get('error');
    if (error === 'session_expired') {
      setErrors([{ msg: 'Sessioon aegunud. Palun logi uuesti sisse.' }]);
    }
  }, [location.search]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    setResendMessage('');

    try {
      await login(email, password);
      navigate('/generate-story');
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors([{ msg: err.response.data.error }]);
      } else {
        setErrors([{ msg: 'Midagi läks valesti. Palun proovi uuesti.' }]);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setResendLoading(true);
    setResendMessage('');
    setErrors([]);
    try {
      const res = await axios.post('/api/users/resend-verification', { email: formData.email });
      setResendMessage(`Kinnituskiri saadeti uuesti aadressile ${formData.email}.`);
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors([{ msg: err.response.data.error }]);
      } else {
        setErrors([{ msg: 'Midagi läks valesti. Palun proovige uuesti.' }]);
      }
    } finally {
      setResendLoading(false);
    }
  };

  if (user) {
    // If user is already logged in, redirect to generate-story
    return <Navigate to="/generate-story" />;
  }

  return (
    <Container fluid className="app-container">
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <AuthCard>
            {/* Logo */}
            <div className="text-center mb-3">
              <img src="/logo.png" alt="Logo" className="logo-img"/>
            </div>
            <h3 className="text-center mb-4">Tere tulemast!</h3>

            {/* Display Errors */}

            {errors.length > 0 && (
                <Alert variant="danger">
                  {errors.map((error, idx) => (
                      <div key={idx}>{error.msg}</div>
                  ))}
                </Alert>
            )}

            {resendMessage && (
                <Alert variant="success">
                  {resendMessage}
                </Alert>
            )}

            <Form onSubmit={onSubmit}>
              <Form.Group controlId="email" className="mb-3">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Sisesta e-mail"
                    name="email"
                    value={email}
                    onChange={onChange}
                    required
                />
              </Form.Group>

              <Form.Group controlId="password" className="mb-3">
                <Form.Label>Parool</Form.Label>
                <Form.Control
                    type="password"
                    placeholder="Sisesta parool"
                    name="password"
                    value={password}
                    onChange={onChange}
                    required
                />
              </Form.Group>

              <div className="d-grid">
                <PrimaryButton type="submit" disabled={loading}>
                  {loading ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> Sisse
                        logimine...
                      </>
                  ) : (
                      'Sisene'
                  )}
                </PrimaryButton>
              </div>
            </Form>

            {/* Resend Verification Email */}
            {errors.some(e => e.msg === 'Palun kinnitage enda kasutaja enne sisse logimist.') && (
                <div className="text-center mt-3">
                  <p>Kas sa ei saanud kinnituskirja?</p>
                  <Button variant="secondary" onClick={handleResendVerification} disabled={resendLoading}>
                    {resendLoading ? (
                        <>
                          <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          />{' '}
                          Saadan...
                        </>
                    ) : (
                        'Saada kinnituskiri uuesti'
                    )}
                  </Button>
                </div>
            )}

            <div className="text-center mt-3">
              <Link to="/forgot-password">Unustasid parooli?</Link>
            </div>
            <div className="text-center mt-3">
              <span>Ei ole kasutajat? </span>
              <Link to="/register">Registreeri</Link>
            </div>

          </AuthCard>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
