// src/components/LandingPage.js

import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Import ReactGA

const LandingPage = () => {
  const handleRegisterClick = (position) => {
    ReactGA.event({
      category: 'User',
      action: 'Click Register Button',
      label: `Landing Page - ${position}`,
    });
  };

  return (
    <Container className="app-container">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="main-card text-center">
            {/* Logo */}
            <div className="text-center mb-3">
              <img src="/logo.png" alt="Unelaegas Logo" className="logo-img" />
            </div>

            {/* Registration and Login Buttons under Logo */}
            <div className="d-flex justify-content-center mb-4">
              <Button
                as={Link}
                to="/register"
                variant="primary"
                size="lg"
                className="me-2 primary-button-2 d-flex align-items-center"
                onClick={() => handleRegisterClick('Top Section')} // Specify position
              >
                Registreeru
              </Button>
              <Button
                as={Link}
                to="/login"
                variant="outline-primary"
                size="lg"
                className="primary-button-2 d-flex align-items-center"
              >
                Logi sisse
              </Button>
            </div>


            {/* New Introduction and Features */}
            <h3 className="landing-call-to-action mb-4">
              Armas lapsevanem, oled leidnud tee unelaekasse!
            </h3>
            <p className="landing-text mb-4">
              Unelaegas on koht, kus sünnivad Sinu lapsele kõige südamelähedasemad ja isiklikumad unejutud.
              Unelaeka looja, (kolme pisipoja isa) Uku, julgeb lubada, et ka teie peres saab uneajast hetk,
              mida kõik elevusega ootavad! Uku on välja treeninud meie erilise tehisintellektiga varustatud
              unelaeka unem(AI)ti, mis valmistab unejutud täpselt Teie ja Teie lapse soovide põhjal.
              Unelaegas on igale perele unikaalne ja personaalne. Lisa lugudesse lapse lemmiktegelasi,
              pereliikmeid, koduõu või muinasjutumets. Soovikorral saab põimida juurde ka näiteks mõne naljaka
              seikluse seljataha jäänud päevast või mõne teema, millega laps pisut julgustust vajab.
              See on imeline viis lapsevanemal aidata selgitada lapsele maailma nende ümber. Kindlasti saavad
              alguse paljud põnevad arutelud lapse ja lapsevanema vahel, mis muidu tähelepanuta võiks jääda.
              Võta õhtul mõnus hetk, loo lapsega üks unejutt ja aita neil mõista maailma nende ümber. Kindlasti loob
              see erilisema, tugevama sideme lapsevanema ja lapse vahel ja loob uinumiseks rahustava keskkonna
              ja positiivsed mõttepildid toredateks unenägudeks. Hea, soe ja positiivne laeng jääb ka lapsevanema
              südamesse- koos veedetud mõnus hetk õhtul ja hea võimalus enda last ja tema üllatusi täis sisemaailma
              paremini tundma õppida.
            </p>
            <p className="landing-text mb-4">
              Seega- unelaegas on mõnus pesa, kus leidub positiivseid emotsioone ja üksteise tundma õppimist nii suurtele kui väikestele!
            </p>
            <p><strong>Personaalsed lood lapse ja lapsevanema valitud tegelaste, kohtade ja teemadega.</strong></p>
            <p><strong>Lood ja sisu keerukus vastavalt lapse vanusevahemikule.</strong></p>
            <p><strong>Lihtne muuta loo pikkust vastavalt õhtusele aja-aknale ja tujule.</strong></p>
            <p><strong>Võimalus lemmik lood salvestada.</strong></p>
            <p><strong>Lisafunktsioon lapsele, kes soovib ise lugema õppida.</strong></p>

            <h3 className="landing-call-to-action mb-4">Alusta juba täna ja loo unustamatuid mälestusi!</h3>
            <div className="d-flex justify-content-center mb-4">
              <Button
                as={Link}
                to="/register"
                variant="primary"
                size="lg"
                className="me-2 primary-button-2 d-flex align-items-center"
                onClick={() => handleRegisterClick('Bottom Section')} // Specify position
              >
                Registreeru
              </Button>
              <Button
                as={Link}
                to="/login"
                variant="outline-primary"
                size="lg"
                className="primary-button-2 d-flex align-items-center"
              >
                Logi sisse
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;