// src/components/Register.js

import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import AuthCard from './AuthCard';
import PrimaryButton from './PrimaryButton';

const Register = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showForm, setShowForm] = useState(true); // State to control form visibility
  const [registeredEmail, setRegisteredEmail] = useState(''); // To store the registered email
  const [resendLoading, setResendLoading] = useState(false); // Loading state for resend verification
  const [resendMessage, setResendMessage] = useState(''); // Message after resending verification
  const [showVerificationInfo, setShowVerificationInfo] = useState(false); // Show verification info conditionally

  const { username, email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validatePassword = () => {
    const errors = [];
    if (password.length < 8) {
      errors.push({ msg: 'Parool peab olema vähemalt 8 tähemärki pikk.' });
    }
    if (!/\d/.test(password)) {
      errors.push({ msg: 'Parool peab sisaldama vähemalt ühte numbrit.' });
    }
    return errors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    setMessage('');
    setResendMessage('');
    setShowVerificationInfo(false); // Reset on each submission

    const validationErrors = validatePassword();
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    try {
      const res = await axios.post('/api/users/register', formData);
      setMessage(res.data.message); // Show reactivation or registration message
      setShowForm(false); // Hide the form on success
      setRegisteredEmail(email); // Store the registered email

      // Check if the response is for a reactivated account, skip verification message
      if (res.data.message === 'Kasutaja edukalt taastatud. Palun logige sisse.') {
        setShowVerificationInfo(false);
      } else {
        setShowVerificationInfo(true); // Show verification message if new registration
      }
    } catch (err) {
      if (err.response && err.response.data) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        } else {
          setErrors([{ msg: err.response.data.error }]);
        }
      } else {
        setErrors([{ msg: 'Midagi läks valesti. Palun proovige uuesti.' }]);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setResendLoading(true);
    setResendMessage('');
    setErrors([]);
    try {
      const res = await axios.post('/api/users/resend-verification', { email: registeredEmail });
      setResendMessage('Kinnituskiri saadeti uuesti aadressile ' + registeredEmail + '.');
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors([{ msg: err.response.data.error }]);
      } else {
        setErrors([{ msg: 'Midagi läks valesti. Palun proovige uuesti.' }]);
      }
    } finally {
      setResendLoading(false);
    }
  };

  if (user) {
    return <Navigate to="/generate-story" />;
  }

  return (
    <Container fluid className="app-container">
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <AuthCard>
            <div className="text-center mb-3">
              <img src="/logo.png" alt="Logo" className="logo-img" />
            </div>

            <h3 className="text-center mb-4">Registreeru</h3>

            {errors.length > 0 && (
              <Alert variant="danger">
                {errors.map((error, idx) => (
                  <div key={idx}>{error.msg}</div>
                ))}
              </Alert>
            )}

            {message && (
              <Alert variant="success">
                {message}
                {showVerificationInfo && (
                  <>
                    <br />
                    Kinnituskiri saadeti aadressile: <strong>{registeredEmail}</strong>
                  </>
                )}
              </Alert>
            )}

            {resendMessage && (
              <Alert variant="success">
                {resendMessage}
              </Alert>
            )}

            {showForm ? (
              <Form onSubmit={onSubmit}>
                <Form.Group controlId="username" className="mb-3">
                  <Form.Label>Kasutajanimi</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sisesta kasutajanimi"
                    name="username"
                    value={username}
                    onChange={onChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Sisesta e-mail"
                    name="email"
                    value={email}
                    onChange={onChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="password" className="mb-3">
                  <Form.Label>Parool</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Sisesta parool"
                    name="password"
                    value={password}
                    onChange={onChange}
                    required
                  />
                  <Form.Text className="text-muted">
                    Parool peab olema vähemalt 8 tähemärki pikk ja sisaldama vähemalt ühte numbrit.
                  </Form.Text>
                </Form.Group>

                <div className="d-grid">
                  <PrimaryButton type="submit" disabled={loading}>
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Registreerimine...
                      </>
                    ) : (
                      'Registreeru'
                    )}
                  </PrimaryButton>
                </div>
              </Form>
            ) : (
              showVerificationInfo && (
                <div className="text-center">
                  <p>
                    Kui sa ei saanud kinnituskirja, saad selle uuesti saata alloleva nupu kaudu.
                  </p>
                  <Button variant="secondary" onClick={handleResendVerification} disabled={resendLoading}>
                    {resendLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Saadan...
                      </>
                    ) : (
                      'Saada kinnituskiri uuesti'
                    )}
                  </Button>
                </div>
              )
            )}

            <div className="text-center mt-3">
              <span>On juba konto? </span>
              <Link to="/login">Logi sisse</Link>
            </div>

          </AuthCard>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
