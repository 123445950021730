// src/components/NotFound.js

import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
            <h2>404 - Lehekülge ei leitud</h2>
            <Link to="/" className="mt-3">
                Tagasi Avalehele
            </Link>
        </Container>
    );
};

export default NotFound;
