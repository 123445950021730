// src/components/PrimaryButton.js

import React from 'react';
import { Button } from 'react-bootstrap';
import './PrimaryButton.css'; // Import the custom CSS

const PrimaryButton = ({ children, variant = 'primary', as, to, ...props }) => {
    return (
        <Button
            as={as}               // Allows the button to render as a different component (e.g., Link)
            to={to}               // Destination path if rendering as a Link
            variant={variant}     // Bootstrap variant
            className="primary-button" // Apply custom styles
            {...props}
        >
            {children}
        </Button>
    );
};

export default PrimaryButton;
