// src/utils/deviceDetection.js

export const getDeviceType = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return 'iOS';
  }

  // Android detection
  if (/android/i.test(ua)) {
    return 'Android';
  }

  return 'Other';
};
