// src/components/StoryDetails.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { Spinner, Alert, Card, Container, Button } from 'react-bootstrap';

const StoryDetails = () => {
    const { id } = useParams(); // Retrieve the story ID from the URL
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();

    const [story, setStory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStory = async () => {
            try {
                const response = await axios.get(`/api/stories/${id}`); // Adjust the endpoint as needed
                setStory(response.data.story); // Adjust based on your API response structure
            } catch (err) {
                console.error('Error fetching story:', err);
                if (err.response && err.response.status === 401) {
                    // If unauthorized, redirect to login
                    navigate('/login');
                } else if (err.response && err.response.status === 404) {
                    setError('Lugu ei leitud.');
                } else {
                    setError('Failed to load the story. Please try again later.');
                }
            } finally {
                setLoading(false);
            }
        };

        if (auth.token) {
            fetchStory();
        } else {
            setLoading(false);
        }
    }, [auth.token, id, navigate]);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
                <Spinner animation="border" variant="primary" />
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="mt-4">
                <Alert variant="danger">{error}</Alert>
                <Button as={Link} to="/past-stories" variant="primary">
                    Tagasi Eelmistesse Juttudesse
                </Button>
            </Container>
        );
    }

    if (!story) {
        return (
            <Container className="mt-4">
                <Alert variant="info">Lugu ei ole saadaval.</Alert>
                <Button as={Link} to="/past-stories" variant="primary">
                    Tagasi Eelmistesse Juttudesse
                </Button>
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            <Card className="story-card">
                <Card.Header className="story-card-header">
                    {story.title}
                </Card.Header>
                <Card.Body className="story-card-body">
                    <Card.Text>{story.content}</Card.Text>
                    <small className="text-muted">Loodud: {new Date(story.createdAt).toLocaleDateString()}</small>
                </Card.Body>
                <Card.Footer>
                    <Button as={Link} to="/past-stories" variant="primary">
                        Tagasi Eelmistesse Juttudesse
                    </Button>
                </Card.Footer>
            </Card>
        </Container>
    );
};

export default StoryDetails;
