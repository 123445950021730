// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from '../api'; // Import the Axios instance with interceptors

// Create AuthContext
export const AuthContext = createContext();

// Check if localStorage is available, otherwise use sessionStorage
const storage = window.localStorage || window.sessionStorage;

// Create AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(storage.getItem('token') || null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  // Fetch user data
  const fetchUser = async () => {
    try {
      const response = await axios.get('/api/users/me');
      setUser(response.data);
      setAuthError(null);
    } catch (error) {
      console.error('Error fetching user:', error);
      setUser(null);
      setToken(null);
      storage.removeItem('token');
      setAuthError('Sessioon aegunud. Palun logi uuesti sisse.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [token]);

  const login = async (email, password) => {
    try {
      const response = await axios.post('/api/users/login', { email, password });
      const receivedToken = response.data.token;
      setToken(receivedToken);
      storage.setItem('token', receivedToken);
      setUser(response.data.user);
      setAuthError(null);
    } catch (error) {
      console.error('Login error:', error);
      const errorMessage = error.response?.data?.error || 'Sisenemine ebaõnnestus. Palun proovi uuesti.';
      setAuthError(errorMessage);
      throw error;
    }
  };

    const logout = () => {
        setUser(null);
        setToken(null);
        storage.removeItem('token');
        setAuthError(null);
        window.location.href = '/login'; // Redirect to login page
    };

    const verifyEmail = async (token, id) => {
        try {
            const response = await axios.get(`/api/users/verify-email?token=${token}&id=${id}`);
            return response.data;
        } catch (error) {
            console.error('Email verification error:', error);
            throw error;
        }
    };

    const forgotPassword = async (email) => {
        try {
            const response = await axios.post('/api/users/forgot-password', { email });
            return response.data;
        } catch (error) {
            console.error('Forgot password error:', error);
            throw error;
        }
    };

    const resetPassword = async (token, id, password) => {
        try {
            const response = await axios.post('/api/users/reset-password', { token, id, password });
            return response.data;
        } catch (error) {
            console.error('Reset password error:', error);
            throw error;
        }
    };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        login,
        logout,
        loading,
        authError,
        verifyEmail,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};