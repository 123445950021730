// src/utils/reactGA.js

import ReactGA from 'react-ga4';

export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; // Use environment variable

let isGAInitialized = false;

// Initialize GA
export const initGA = (callback) => {
    if (isGAInitialized) {
        console.log('GA is already initialized');
        if (callback) callback();
        return;
    }

    ReactGA.initialize(GA_TRACKING_ID, {
        gaOptions: {
            page_path: window.location.pathname,
        },
    });

    isGAInitialized = true;

    if (callback) callback();
};

// Log page view
export const logPageView = (path) => {
    if (isGAInitialized) {
        ReactGA.send({ hitType: 'pageview', page: path });
    } else {
        console.warn('GA not initialized yet.');
    }
};
