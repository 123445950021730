// src/components/ResetPassword.js

import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import AuthCard from './AuthCard';

const ResetPassword = () => {
    const { resetPassword } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const id = query.get('id');

    const validatePassword = () => {
        const errors = [];
        if (password.length < 8) {
            errors.push({ msg: 'Parool peab olema vähemalt 8 tähemärki pikk.' });
        }
        if (!/\d/.test(password)) {
            errors.push({ msg: 'Parool peab sisaldama vähemalt ühte numbrit.' });
        }
        if (password !== confirmPassword) {
            errors.push({ msg: 'Paroolid ei ühti.' });
        }
        return errors;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors([]);
        setMessage('');

        // Validate password requirements
        const validationErrors = validatePassword();
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setLoading(false);
            return;
        }

        try {
            const res = await resetPassword(token, id, password);
            setMessage(res.message);
            setTimeout(() => navigate('/login'), 3000);
        } catch (err) {
            if (err.response && err.response.data) {
                setErrors([{ msg: err.response.data.error }]);
            } else {
                setErrors([{ msg: 'Midagi läks valesti. Palun proovige uuesti.' }]);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid className="app-container">
            <Row className="justify-content-center">
                <Col xs={12} md={6} lg={4}>
                    <AuthCard>
                        <h3 className="text-center mb-4">Salasõna muutmine</h3>

                        {errors.length > 0 && (
                            <Alert variant="danger">
                                {errors.map((error, idx) => (
                                    <div key={idx}>{error.msg}</div>
                                ))}
                            </Alert>
                        )}

                        {message && <Alert variant="success">{message}</Alert>}

                        <Form onSubmit={onSubmit}>
                            <Form.Group controlId="password" className="mb-3">
                                <Form.Label>Uus Parool</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Sisesta uus parool"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <Form.Text className="text-muted">
                                    Parool peab olema vähemalt 8 tähemärki pikk ja sisaldama vähemalt ühte numbrit.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="confirmPassword" className="mb-3">
                                <Form.Label>Kinnita Uus Parool</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Kinnita uus parool"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <div className="d-grid">
                                <Button type="submit" variant="secondary" className="back-button primary-button-2 align-items-center" disabled={loading}>
                                    {loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            Lähtestamine...
                                        </>
                                    ) : (
                                        'Muuda salasõna'
                                    )}
                                </Button>
                            </div>
                        </Form>

                        <div className="text-center mt-3">
                            <span>Parool tuli meelde? </span>
                            <Link to="/login">Logi sisse</Link>
                        </div>
                    </AuthCard>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPassword;
