// src/components/GenerateStory.js

import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Spinner,
    Alert,
    Card,
    Collapse,
    Badge,
    OverlayTrigger,
    Tooltip,
    Modal
} from 'react-bootstrap';
import {
    FaMagic,
    FaPlus,
    FaMinus,
    FaCheckCircle,
    FaBook,
    FaSignOutAlt,
    FaInfoCircle
} from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import axios from '../api';
import { Link, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactGA from 'react-ga4'; // Import ReactGA
import InstructionsModal from './InstructionsModal'; // Import the InstructionsModal component
import { getDeviceType } from '../utils/deviceDetection'; // Import device detection utility

const GenerateStory = () => {
    const { logout, user } = useContext(AuthContext); // Added for user settings
    const navigate = useNavigate();

    // State variables for story generation
    const [storyTitle, setStoryTitle] = useState('');
    const [story, setStory] = useState('');
    const [loading, setLoading] = useState(false);
    const [ageRange, setAgeRange] = useState('1-2');
    const [error, setError] = useState('');
    const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);
    const [additionalDetails, setAdditionalDetails] = useState({
        characters: '',
        places: '',
        themes: ''
    });
    const [currentStoryId, setCurrentStoryId] = useState(null);

    // State Variables for Longer Story
    const [isLonger, setIsLonger] = useState(false);

    // New State Variable for Learning to Read Mode
    const [isLearningMode, setIsLearningMode] = useState(false);

    // State Variables for Save Button Animation
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    // State for User Credits
    const [credits, setCredits] = useState(0);
    const [creditsLoading, setCreditsLoading] = useState(true);
    const [creditsError, setCreditsError] = useState('');

    // Refs for buffer and state tracking
    const bufferRef = useRef('');
    const isTitleSetRef = useRef(false);
    const isStoryStartedRef = useRef(false);

    // State for User Settings Modal
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [settingsUsername, setSettingsUsername] = useState(user?.username || '');
    const [settingsEmail, setSettingsEmail] = useState(user?.email || '');
    const [settingsIsEditing, setSettingsIsEditing] = useState(false);
    const [settingsDeleteMode, setSettingsDeleteMode] = useState(false);
    const [settingsDeletePassword, setSettingsDeletePassword] = useState('');
    const [settingsMessage, setSettingsMessage] = useState('');
    const [settingsLoading, setSettingsLoading] = useState(false);
    const [settingsError, setSettingsError] = useState('');
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [settingsCurrentPassword, setSettingsCurrentPassword] = useState('');
    const [settingsNewPassword, setSettingsNewPassword] = useState('');

    // State Variables for Instructions Modal
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [deviceType, setDeviceType] = useState('Other');

    // --- **Key Additions Begin Here** ---

    // 1. Define the 'cleanTitle' function before it's used in 'generateStory'
    const cleanTitle = (title) => {
        return title.replace(/["*#]/g, '').trim();
    };

    // 2. Define 'handleToggleAdditionalInputs' and 'handleAdditionalDetailsChange' before their usage in JSX
    const handleToggleAdditionalInputs = () => {
        setShowAdditionalInputs(!showAdditionalInputs);
    };

    const handleAdditionalDetailsChange = (e) => {
        const { name, value } = e.target;
        setAdditionalDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    // Remove the 'shouldShowModal' function and related logic since the modal is no longer shown automatically

    useEffect(() => {
        // Fetch user credits on component mount
        const fetchCredits = async () => {
            try {
                const response = await axios.get('/api/users/me');
                setCredits(response.data.credits);
            } catch (err) {
                setCreditsError('Viga unemüntide laadimisel.');
            } finally {
                setCreditsLoading(false);
            }
        };

        fetchCredits();

        // Device detection
        const currentDevice = getDeviceType();
        setDeviceType(currentDevice);

        // Remove the automatic display of the Instructions Modal
        // Previously:
        // if (shouldShowModal()) {
        //     setShowInstructionsModal(true);
        // }

        // Initialize GA if needed (assuming GA is initialized elsewhere or here if consented)
        // Example:
        /*
        const consent = Cookies.get('unelaegas_cookie_consent');
        if (consent === 'true') {
            initGA(() => {
                setIsGAInitialized(true);
            });
        }
        */

    }, []);

    // Handler for closing the Instructions Modal
    const handleCloseInstructionsModal = () => {
        setShowInstructionsModal(false);
        // Since the modal is no longer shown automatically, you might not need to set any flags here
    };

    // --- **Key Additions End Here** ---

    // Function to generate a story using the backend API with streaming
    const generateStory = async () => {
        // Check if user has sufficient credits before proceeding
        const requiredCredits = isLonger ? 2 : 1;
        if (credits < requiredCredits) {
            setError('Sul pole piisavalt unemünte. Palun osta juurde vajutades "Unemündid" nupule lehe ülaosas.');
            return;
        }

        // Optimistically update the credit count
        setCredits(prevCredits => prevCredits - requiredCredits);

        setLoading(true);
        setError('');
        setStory('');
        setStoryTitle('');
        setCurrentStoryId(null);
        setIsSaved(false);
        isTitleSetRef.current = false;
        isStoryStartedRef.current = false;
        bufferRef.current = '';

        try {
            // Prepare payload with age range and additional details if provided
            const payload = {
                ageRange,
                isLonger, // Include isLonger in the payload
                isLearningMode // Include isLearningMode in the payload
            };

            if (additionalDetails.characters || additionalDetails.places || additionalDetails.themes) {
                payload.characters = additionalDetails.characters;
                payload.places = additionalDetails.places;
                payload.themes = additionalDetails.themes;
            }


            // Fetch request to generate the story
            const response = await fetch('/api/generate-story', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error response from server:', errorData);
                throw new Error(errorData.error || 'Viga loo genereerimisel. Palun proovi uuesti.');
            }


            // Process the response as a stream
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let isEndOfStory = false;

            const processBufferFunction = (chunk) => {
                bufferRef.current += chunk;

                // Detect and Handle Error Messages
                const errorRegex = /\[ERROR\]:\s*(.*)/i;
                const errorMatch = bufferRef.current.match(errorRegex);
                if (errorMatch) {
                    const errorMessage = errorMatch[1].trim();
                    setError(errorMessage);
                    // Stop further processing
                    isEndOfStory = true;
                    reader.cancel(); // Cancel the stream
                    return;
                }

                // Extract title if not set
                if (!isTitleSetRef.current) {
                    const titleRegex = /Pealkiri:\s*(.*)\n/i;
                    const titleMatch = bufferRef.current.match(titleRegex);
                    if (titleMatch) {
                        let extractedTitle = cleanTitle(titleMatch[1]);
                        if (isLearningMode) {
                            extractedTitle = extractedTitle.toUpperCase();
                        }
                        setStoryTitle(extractedTitle);
                        // Remove the title line from buffer
                        bufferRef.current = bufferRef.current.substring(titleMatch.index + titleMatch[0].length);
                        isTitleSetRef.current = true;
                    }
                }

                // Extract story if title is set and story hasn't started
                if (isTitleSetRef.current && !isStoryStartedRef.current) {
                    const storyRegex = /Unejutt:\s*([\s\S]*)/i;
                    const storyMatch = bufferRef.current.match(storyRegex);
                    if (storyMatch) {
                        let extractedStory = storyMatch[1];
                        if (isLearningMode) {
                            extractedStory = extractedStory.toUpperCase();
                        }
                        setStory(extractedStory);
                        // Remove the story label from buffer
                        bufferRef.current = bufferRef.current.substring(storyMatch.index + storyMatch[0].length);
                        isStoryStartedRef.current = true;
                    }
                }

                // Check for end marker
                const endMarkerIndex = bufferRef.current.indexOf('[END_OF_STORY]');
                if (endMarkerIndex !== -1) {
                    let storyContent = bufferRef.current.substring(0, endMarkerIndex).trim();
                    if (isLearningMode) {
                        storyContent = storyContent.toUpperCase();
                    }
                    setStory(prevStory => prevStory + storyContent);

                    const jsonStartIndex = endMarkerIndex + '[END_OF_STORY]\n'.length;
                    const jsonString = bufferRef.current.substring(jsonStartIndex).trim();
                    try {
                        const json = JSON.parse(jsonString);
                        setCurrentStoryId(json.storyId);
                    } catch (e) {
                        console.error('Error parsing story ID:', e);
                    }

                    isEndOfStory = true;
                    // Clear the buffer
                    bufferRef.current = '';
                    return;
                }

                // Append remaining buffer to story if title and story have been set
                if (isTitleSetRef.current && isStoryStartedRef.current && bufferRef.current.length > 0) {
                    let appendedContent = bufferRef.current;
                    if (isLearningMode) {
                        appendedContent = appendedContent.toUpperCase();
                    }
                    setStory(prevStory => prevStory + appendedContent);
                    bufferRef.current = '';
                }
            };

            const processStream = async () => {
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    const chunk = decoder.decode(value);
                    processBufferFunction(chunk);

                    if (isEndOfStory) {
                        break;
                    }
                }

                // Ensure loading is set to false
                setLoading(false);

                // Refetch Credits to Synchronize State
                fetchCredits();
            };

            await processStream();

        } catch (error) {
            setError(error.message || 'Viga loo genereerimisel. Palun proovi uuesti.');

            // Notify the user via Alert
            alert(`Viga loo genereerimisel: ${error.message || 'Palun proovi uuesti.'}`);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle logout
    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    // Function to handle saving the story
    const handleSaveStory = async () => {
        if (!currentStoryId) {
            console.warn('No currentStoryId to save.');
            return;
        }

        setIsSaving(true);
        setIsSaved(false);

        try {
            await axios.post(`/api/stories/${currentStoryId}/save`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setIsSaved(true);
        } catch (error) {
            setError('Loo salvestamine ebaõnnestus. Palun proovi uuesti.');
            // **Optional: Notify the user via Alert**
            alert('Loo salvestamine ebaõnnestus. Palun proovi uuesti.');
        } finally {
            setIsSaving(false);
            // Reset the saved status after a short delay (e.g., 2 seconds)
            setTimeout(() => {
                setIsSaved(false);
            }, 2000);
        }
    };

    // Function to fetch user credits (used after story generation)
    const fetchCredits = async () => {
        try {
            const response = await axios.get('/api/users/me');
            setCredits(response.data.credits);
        } catch (err) {
            console.error('Error fetching updated credits:', err);
            setCreditsError('Viga unemüntide laadimisel');
        }
    };

    // Functions to handle User Settings Modal
    const handleShowSettingsModal = () => setShowSettingsModal(true);
    const handleCloseSettingsModal = () => {
        setShowSettingsModal(false);
        setSettingsIsEditing(false);
        setSettingsDeleteMode(false);
        setSettingsError('');
        setSettingsMessage('');
    };

    // Function to handle user updates
    const handleUpdateUser = async () => {
        setSettingsLoading(true);
        setSettingsMessage('');
        setSettingsError('');
        try {
            await axios.put('/api/users/update', {
                username: settingsUsername,
                email: settingsEmail,
                currentPassword: settingsCurrentPassword,
                newPassword: settingsNewPassword
            });
            setSettingsMessage('Kasutaja andmed uuendatud.');
            setSettingsIsEditing(false);
        } catch (err) {
            console.error('Error updating user:', err);
            setSettingsError('Viga andmete uuendamisel.');
        } finally {
            setSettingsLoading(false);
        }
    };


    // Function to handle user deletion
    const handleDeleteUser = async () => {
        setSettingsLoading(true);
        setSettingsError('');
        setSettingsMessage('');
        try {
            await axios.delete('/api/users/delete', { data: { password: settingsDeletePassword } });
            logout(); // Log out the user after deletion
        } catch (err) {
            console.error('Error deleting user:', err);
            setSettingsError('Kasutaja kustutamine ebaõnnestus. Kontrollige salasõna.');
        } finally {
            setSettingsLoading(false);
        }
    };

    // Function to handle password change
    const handleChangePassword = async () => {
        setSettingsLoading(true);
        setSettingsMessage('');
        setSettingsError('');

        try {
            // Make API request to update the password
            await axios.put('/api/users/update-password', {
                currentPassword: settingsCurrentPassword,
                newPassword: settingsNewPassword,
            });

            setSettingsMessage('Parool uuendatud.');
            setShowPasswordChange(false); // Hide the password change fields after success
            setSettingsCurrentPassword('');
            setSettingsNewPassword('');
        } catch (err) {
            console.error('Error changing password:', err);
            setSettingsError('Viga parooli uuendamisel. Kontrollige praegust salasõna.');
        } finally {
            setSettingsLoading(false);
        }
    };

    return (
        <Container fluid className="app-container">
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <div className="main-card">
                        {/* Buttons Container */}
                        <div className="buttons-container d-flex justify-content-around mb-3 align-items-center navigation">
                            {/* Unemündid Button */}
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="unemundid-tooltip">Osta rohkem unemünte</Tooltip>}
                            >
                                <Button
                                    variant="primary"
                                    as={Link}
                                    to="/buy-unemundid"
                                    className="me-2 primary-button-2 align-items-center"
                                    aria-label="Osta rohkem Unemündid"
                                >
                                    <span>
                                        Unemündid: <Badge bg="light" text="dark">{credits}</Badge>
                                    </span>
                                </Button>
                            </OverlayTrigger>

                            <Button
                                as={Link}
                                to="/past-stories"
                                className="me-2 primary-button-2 d-flex align-items-center"
                                aria-label="Navigate to Past Stories"
                            >
                                Salvestatud jutud
                            </Button>


                            {/* Updated "Logi välja" Button with Icon */}
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="logout-tooltip">Logi välja</Tooltip>}
                            >
                                <Button
                                    variant="outline-danger"
                                    onClick={handleLogout}
                                    aria-label="Log out of the application"
                                    className="logout-button"
                                >
                                    <FaSignOutAlt/>
                                </Button>
                            </OverlayTrigger>
                        </div>

                        {/* Logo */}
                        <div className="text-center mb-3 logo-spacing">
                            <img src="/logo.png" alt="Logo" className="logo-img"/>
                        </div>

                        {/* User Info, Settings, and Delete Account Modal */}
                        <Modal show={showSettingsModal} onHide={handleCloseSettingsModal} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Info ja seaded</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* Add the button to open the Instructions Modal */}
                                <div><p><strong>Parema kasutajakogemuse nutiseadmetes saate installides rakenduse:</strong></p></div>
                                <div className="mb-3">
                                    <Button
                                        variant="secondary"
                                        onClick={() => setShowInstructionsModal(true)}
                                        className="back-button primary-button-2 align-items-center"
                                        aria-label="Open Installation Instructions"
                                    >
                                        Vaata installeerimise juhiseid
                                    </Button>
                                </div>

                                {/* Full App Description */}

                                <p>
                                    Rakenduse põhifunktsioonid:
                                    <ul>
                                        <li><strong>Vanusevahemike valik:</strong> Valige oma lapse vanuse järgi sobiv unejutt, mis vastab nende arenguastmele ja huvidele.</li>
                                        <li><strong>Isikupärastamine:</strong> Lisage lugudele oma lapse lemmiktegelasi, kohti või teemasid, et muuta lood veelgi isikupärasemaks ja põnevamaks.</li>
                                        <li><strong>Unemündid:</strong> Genereerige lugusid kasutades unemünte, mida saate osta rakenduse ülaosas olevale "Unemündid" nupule vajutades. Ühe unemündi eest saate loo, mis kestab ette lugedes umbes 5–7 minutit, ning 2-3 korda pikemate lugude jaoks saate kasutada kaks unemünti.</li>
                                        <li><strong>Pikemad lood:</strong> Soovi korral valige "Pikem unejutt", mis kasutab kaks unemünti ja genereerib pikema ning põhjalikuma loo, pakkudes rohkem seiklusi ja põnevust.</li>
                                        <li><strong>Õpin lugema režiim:</strong> Kasutage lugema õppimise režiimi, mis genereerib lihtsamaid tekste ja trükitähtedega lugusid, et aidata teie lapsel lugemisoskust arendada.</li>
                                        <li><strong>Salvestatud lood:</strong> Kui mõni unejutt tundub eriti armas ja meeldejääv, saate selle salvestada, vajutades loo lõpus olevat "Salvesta unejutt" nuppu. Salvestatud lood on kättesaadavad "Salvestatud jutud" lehel, võimaldades teil lugusid korduvalt ette lugeda.</li>
                                        <li><strong>Kasutaja seaded:</strong> Hallake oma profiili andmeid, uuendage kasutajanime, e-posti aadressi või parooli.</li>
                                    </ul>
                                </p>
                                <p>
                                    Kuna kõik lood on tehisintellekti poolt genereeritud, võib esineda mõningaid ebatäpsusi. Meie meeskond täiustab mudelit pidevalt, et minimeerida võimalikke vigu ja pakkuda parimat lugemiskogemust. Teie tagasiside on meile äärmiselt oluline ning aitab meil rakendust veelgi paremaks muuta. Rakendus on arendatud ja hallatud <b>UQ OÜ</b> poolt.
                                </p>


                                <hr />

                                {/* User Info */}
                                <h5>Kasutaja andmed</h5>
                                {settingsMessage && <Alert variant="success">{settingsMessage}</Alert>}
                                {settingsError && <Alert variant="danger">{settingsError}</Alert>}

                                <Form>
                                    <Form.Group controlId="username" className="mb-3">
                                        <Form.Label>Kasutajanimi</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={settingsUsername}
                                            disabled={!settingsIsEditing}
                                            onChange={(e) => setSettingsUsername(e.target.value)}
                                            aria-label="Kasutajanimi"
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="email" className="mb-3">
                                        <Form.Label>E-mail</Form.Label>
                                        <Form.Control
                                            type="email"
                                            value={settingsEmail}
                                            disabled={!settingsIsEditing}
                                            onChange={(e) => setSettingsEmail(e.target.value)}
                                            aria-label="E-mail"
                                        />
                                    </Form.Group>

                                    {/* Toggle Button for Editing User Info */}
                                    {settingsIsEditing ? (
                                        <Button variant="primary" onClick={handleUpdateUser} disabled={settingsLoading}>
                                            {settingsLoading ? <Spinner animation="border" size="sm" /> : 'Uuenda andmed'}
                                        </Button>
                                    ) : (
                                        <Button variant="secondary" className="back-button primary-button-2 align-items-center" onClick={() => setSettingsIsEditing(true)}>
                                            Muuda andmed
                                        </Button>
                                    )}
                                </Form>

                                {/* Toggle for Password Change */}
                                <div className="mt-3">
                                    <Button
                                        variant="secondary"
                                        onClick={() => setShowPasswordChange(!showPasswordChange)}
                                        aria-controls="password-change-form"
                                        className="back-button primary-button-2 align-items-center"
                                        aria-expanded={showPasswordChange}
                                    >
                                        Muuda parooli
                                    </Button>
                                </div>

                                {/* Password Change Fields */}
                                {showPasswordChange && (
                                    <Form id="password-change-form" className="mt-3">
                                        <Form.Group controlId="currentPassword" className="mb-3">
                                            <Form.Label>Praegune salasõna</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Sisestage praegune salasõna"
                                                value={settingsCurrentPassword}
                                                onChange={(e) => setSettingsCurrentPassword(e.target.value)}
                                                aria-label="Praegune salasõna"
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="newPassword" className="mb-3">
                                            <Form.Label>Uus salasõna</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Sisestage uus salasõna"
                                                value={settingsNewPassword}
                                                onChange={(e) => setSettingsNewPassword(e.target.value)}
                                                aria-label="Uus salasõna"
                                            />
                                        </Form.Group>

                                        <Button variant="secondary"  onClick={handleChangePassword} className="back-button primary-button-2 align-items-center" disabled={settingsLoading}>
                                            {settingsLoading ? <Spinner animation="border" size="sm" /> : 'Uuenda parool'}
                                        </Button>
                                    </Form>
                                )}

                                <hr />

                                {/* Delete Account Section */}
                                <h5>Kustuta konto</h5>
                                {settingsDeleteMode ? (
                                    <>
                                        <Form.Group controlId="deletePassword" className="mb-3">
                                            <Form.Label>Sisestage salasõna kinnitamiseks</Form.Label>
                                            <Form.Control
                                                type="password"
                                                value={settingsDeletePassword}
                                                onChange={(e) => setSettingsDeletePassword(e.target.value)}
                                                aria-label="Sisestage salasõna"
                                            />
                                        </Form.Group>
                                        <Button variant="danger" onClick={handleDeleteUser} disabled={settingsLoading}>
                                            {settingsLoading ? <Spinner animation="border" size="sm" /> : 'Kustuta konto'}
                                        </Button>
                                    </>
                                ) : (
                                    <Button variant="outline-danger" onClick={() => setSettingsDeleteMode(true)}>
                                        Kustuta konto
                                    </Button>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={handleCloseSettingsModal}
                                    className="back-button primary-button-2 align-items-center"
                                    aria-label="Close User Settings"
                                >
                                    Sulge
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Instructions Modal */}
                        <InstructionsModal
                            show={showInstructionsModal}
                            handleClose={handleCloseInstructionsModal}
                            deviceType={deviceType}
                        />

                        {/* Story Generation Form */}
                        <Form>
                            {/* Age Range Selection */}
                            <Form.Group controlId="ageRange" className="mb-3">
                                <Form.Label className="age-label">Vali mis vanuses lastele lugu on mõeldud</Form.Label>
                                <Form.Select
                                    value={ageRange}
                                    onChange={(e) => setAgeRange(e.target.value)}
                                    className="custom-select"
                                    aria-label="Select age range"
                                >
                                    <option value="1-2">1 kuni 2 aastat</option>
                                    <option value="3-5">3 kuni 5 aastat</option>
                                    <option value="6-8">6 kuni 8 aastat</option>
                                    <option value="9-12">9 kuni 12 aastat</option>
                                </Form.Select>
                            </Form.Group>

                            {/* Toggle Additional Inputs */}
                            <Button
                                variant="outline-primary"
                                className="toggle-button mb-3"
                                onClick={handleToggleAdditionalInputs}
                                aria-controls="additional-inputs"
                                aria-expanded={showAdditionalInputs}
                                aria-label="Toggle additional details"
                            >
                                {showAdditionalInputs ? (
                                    <>
                                        <FaMinus/> Peida
                                    </>
                                ) : (
                                    <>
                                        <FaPlus/> Muuda lugu isikupärasemaks
                                    </>
                                )}
                            </Button>

                            {/* Additional Inputs */}
                            <Collapse in={showAdditionalInputs}>
                                <div id="additional-inputs" className="additional-inputs">
                                    <p>
                                        Siin saad lisada erinevaid detaile, mida kasutatakse loo genereerimisel.
                                        Parima tulemuse saamiseks täida kõik lahtrid. Kasutades tuntud tegelasi
                                        või kohti võib lugu sisaldada asju, mis ei vasta tegelikkusele.
                                    </p>
                                    <Form.Group controlId="characters" className="mb-3">
                                        <Form.Label>Tegelased</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Näiteks: Juku, Mari, Padakonn, Vennad"
                                            name="characters"
                                            value={additionalDetails.characters}
                                            onChange={handleAdditionalDetailsChange}
                                            aria-label="Enter characters"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="places" className="mb-3">
                                        <Form.Label>Kohad</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Näiteks: Mets, Teletorn, Vanaema ja Vanaisa talu"
                                            name="places"
                                            value={additionalDetails.places}
                                            onChange={handleAdditionalDetailsChange}
                                            aria-label="Enter places"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="themes" className="mb-3">
                                        <Form.Label>Teemad</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Näiteks: Sõprus, Hirmudest üle saamine, Avastamine"
                                            name="themes"
                                            value={additionalDetails.themes}
                                            onChange={handleAdditionalDetailsChange}
                                            aria-label="Enter themes"
                                        />
                                    </Form.Group>
                                </div>
                            </Collapse>

                            {/* Longer Story Checkbox */}
                            <Form.Group controlId="isLonger" className="mb-3">
                                <p><b>Kui soovid, et lugu oleks pikem ja põhjalikum:</b></p>
                                <Form.Check
                                    type="checkbox"
                                    label="Pikem unejutt (kasutab 2 unemünti)"
                                    checked={isLonger}
                                    onChange={(e) => setIsLonger(e.target.checked)}
                                    aria-label="Pikem unejutt (kasutab 2 unemünti)"
                                />
                            </Form.Group>

                            {/* Learning to Read Mode Checkbox */}
                            <Form.Group controlId="isLearningMode" className="mb-3">
                                <p><b>Genereeri lugu lapsele ise lugemiseks:</b></p>
                                <Form.Check
                                    type="checkbox"
                                    label="Lihtsam tekst trükitähtedena"
                                    checked={isLearningMode}
                                    onChange={(e) => setIsLearningMode(e.target.checked)}
                                    aria-label="Õppe lugemise režiim"
                                />
                            </Form.Group>

                            {/* Generate Story Button */}
                            <div className="d-grid">
                                <Button
                                    className="generate-button"
                                    onClick={generateStory}
                                    disabled={loading}
                                    size="lg"
                                    aria-label="Generate Story"
                                >
                                    {loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            Genereerimine...
                                        </>
                                    ) : (
                                        <>
                                            <FaMagic style={{marginRight: '0.5rem'}}/>
                                            Genereeri Unejutt
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Form>

                        {/* Error Alert */}
                        {error && (
                            <Alert variant="danger" className="mt-3">
                                {error}
                            </Alert>
                        )}

                        {/* Story Display */}
                        {story && (
                            <Card className="mt-4 story-card">
                                <Card.Header className="story-card-header d-flex align-items-center">
                                    <FaBook className="me-2" aria-label="Story icon"/>
                                    {storyTitle ? storyTitle : 'Pealkiri puudub'}
                                </Card.Header>
                                <Card.Body className="d-flex flex-column">
                                    {/* Use ReactMarkdown for rendering Markdown content */}
                                    <ReactMarkdown>{story}</ReactMarkdown>

                                    {/* "Salvesta lugu" Button with Checkmark */}
                                    <div className="d-flex justify-content-end mt-3">
                                        <Button
                                            variant="success"
                                            onClick={handleSaveStory}
                                            disabled={isSaving || isSaved}
                                            className="save-button"
                                            aria-label="Salvesta lugu"
                                        >
                                            {isSaving ? (
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    Salvestamine...
                                                </>
                                            ) : isSaved ? (
                                                <FaCheckCircle style={{color: 'white', marginRight: '0.5rem'}}/>
                                            ) : (
                                                'Salvesta lugu'
                                            )}
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        )}

                        {/* Disclaimer */}
                        <div className="disclaimer mt-4">
                            <p>
                                Kõik lood on AI poolt genereeritud ja võivad sisaldada ebatäpsusi. See rakendus on
                                arendatud ja hallatud <strong>UQ OÜ</strong> poolt (<a
                                href="mailto:ver@uq-it.ee">ver@uq-it.ee</a>). Rakendus on veel arendamise all ning kõik
                                tagasiside on teretulnud.
                            </p>
                        </div>
                        {/* Info ja Seaded Button */}
                        <Button
                            variant="secondary"
                            onClick={handleShowSettingsModal}
                            className="me-2 primary-button-2 align-items-center"
                            aria-label="Open User Settings"
                        >
                            <FaInfoCircle size={24}/> Info ja seaded
                        </Button>
                    </div>
                </Col>
            </Row>

            {/* User Info, Settings, and Delete Account Modal */}
            {/* This modal is already included above within the main card */}
        </Container>
    );

};

export default GenerateStory;
