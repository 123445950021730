// src/App.js

import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import GenerateStory from './components/GenerateStory';
import PastStories from './components/PastStories';
import StoryDetails from './components/StoryDetails';
import VerifyEmail from './components/VerifyEmail';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import BuyUnemundid from './components/BuyUnemundid';
import PaymentSuccess from './components/PaymentSuccess';
import LandingPage from './components/LandingPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import { AuthContext } from './context/AuthContext';
import { Spinner, Container } from 'react-bootstrap';
import NotFound from './components/NotFound';
import CookieConsent from 'react-cookie-consent';
import { initGA, logPageView } from './utils/reactGA'; // Updated import
import Cookies from 'js-cookie'; // Import js-cookie

const GAListener = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname + location.search);
    }, [location]);

    return null; // GAListener doesn't render any UI
};

const PrivateRoute = ({ children }) => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
                <Spinner animation="border" variant="primary" />
            </Container>
        );
    }

    return user ? children : <Navigate to="/login" />;
};

const App = () => {
    const { loading, user } = useContext(AuthContext);
    const [isGAInitialized, setIsGAInitialized] = useState(false);

    useEffect(() => {
        // Check if the consent cookie exists and is set to 'true'
        const consent = Cookies.get('unelaegas_cookie_consent');
        if (consent === 'true') { // Adjust based on your consent cookie value
            initGA(() => {
                setIsGAInitialized(true);
            });
        }
    }, []);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                <Spinner animation="border" variant="primary" />
            </Container>
        );
    }

    return (
        <Router>
            {/* Conditionally render GAListener only if GA is initialized */}
            {isGAInitialized && <GAListener />}

            <div className="App">
                <Routes>
                    {/* Public Routes */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/verify-email" element={<VerifyEmail />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/payment-success" element={<PaymentSuccess />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                    {/* Protected Routes */}
                    <Route
                        path="/generate-story"
                        element={
                            <PrivateRoute>
                                <GenerateStory />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/past-stories"
                        element={
                            <PrivateRoute>
                                <PastStories />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/past-stories/:id"
                        element={
                            <PrivateRoute>
                                <StoryDetails />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/buy-unemundid"
                        element={
                            <PrivateRoute>
                                <BuyUnemundid />
                            </PrivateRoute>
                        }
                    />

                    {/* Home Route */}
                    <Route
                        path="/"
                        element={user ? <Navigate to="/generate-story" /> : <LandingPage />}
                    />

                    {/* Fallback Route */}
                    <Route path="*" element={<NotFound />} />
                </Routes>

                {/* Cookie Consent Banner */}
                <CookieConsent
                    location="bottom"
                    buttonText="Sain aru"
                    cookieName="unelaegas_cookie_consent"
                    containerClasses="cookie-consent-container"
                    buttonClasses="cookie-consent-button"
                    declineButtonClasses="cookie-consent-decline"
                    expires={150}
                    onAccept={() => {
                        initGA(() => {
                            setIsGAInitialized(true);
                        });
                    }}
                >
                    Kasutame küpsiseid, et parandada teie kogemust meie veebilehel. <a href="/privacy-policy">Loe lähemalt</a>.
                </CookieConsent>
            </div>
        </Router>
    );
};

export default App;
