// src/components/PaymentSuccess.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import './PaymentSuccess.css';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Simulate loading and then display success message
        setTimeout(() => {
            setMessage('Teid suunatakse peagi edasi avalehele.');
            setLoading(false);

            // Redirect to /generate-story after 3 seconds
            setTimeout(() => {
                navigate('/generate-story');
            }, 3000);
        }, 500); // Optional delay to simulate loading
    }, [navigate]);

    return (
        <Container className="d-flex justify-content-center align-items-center vh-100">
            <Card className="success-card text-center p-4">
                <div className="d-flex justify-content-center align-items-center">
                    <FaCheckCircle className="success-icon" size={100} color="#28a745" />
                </div>
                <Card.Body>
                    <Card.Title className="mb-4">
                        <h2 style={{ fontWeight: 'bold' }}>Maksmine õnnestus!</h2>
                    </Card.Title>
                    <Card.Text>{message}</Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default PaymentSuccess;
