// src/components/AuthCard.js

import React from 'react';
import { Card } from 'react-bootstrap';

const AuthCard = ({ children }) => {
    return (
        <Card className="main-card">
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    );
};

export default AuthCard;
