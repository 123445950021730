// src/components/InstructionsModal.js

import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4'; // Optional: For Google Analytics event tracking

const InstructionsModal = ({ show, handleClose, deviceType }) => {
  // Define content based on device type
  const getContent = () => {
    if (deviceType === 'iOS') {
      return {
        title: 'Kuidas installida Unelaegas iOS seadmesse',
        body: (
          <>
            <p>
              Parema kasutajakogemuse jaoks soovitame lisada Unelaegas oma koduekraanile. Järgige alltoodud samme:
            </p>
            <ol>
              <li>Ava Safari brauser.</li>
              <li>Külasta Unelaegas veebilehte.</li>
              <li>Klõpsa jagamisikoonil.</li>
              <li>Vali "Add to home screen".</li>
              <li>Määra nimeks "Unelaegas" ja kinnita.</li>
            </ol>
            <Image src="/ios1.jpg" alt="iOS Installation Instructions" fluid />
            <Image src="/ios2.jpg" alt="iOS Installation Instructions2" fluid />
          </>
        ),
      };
    } else if (deviceType === 'Android') {
      return {
        title: 'Kuidas installida Unelaegas Android seadmesse',
        body: (
          <>
            <p>
              Parema kasutajakogemuse jaoks soovitame lisada Unelaegas oma koduekraanile. Järgige alltoodud samme:
            </p>
            <ol>
              <li>Ava Chrome brauser.</li>
              <li>Külasta Unelaegas veebilehte.</li>
              <li>Klõpsa menüünupule (kolm punkti ülanurgas).</li>
              <li>Vali "Lisa koduekraanile".</li>
              <li>Kinnita nimeks "Unelaegas".</li>
            </ol>
            <Image src="/android1.jpg" alt="Android Installation Instructions" fluid />
            <Image src="/android2.jpg" alt="Android Installation Instructions2" fluid />
          </>
        ),
      };
    } else {
      return {
        title: 'Unelaeka installimine arvutis',
        body: (
          <>
            <p>
              Unelaegas on installeeritav veebirakendus. Kui soovite arvutis installeerida, siis jälgige neid juhiseid:
            </p>

            {/* Google Chrome ja Microsoft Edge */}
            <h5>Google Chrome ja Microsoft Edge:</h5>
            <ol>
              <li>Ava oma veebibrauser (Google Chrome või Microsoft Edge).</li>
              <li>Külasta Unelaegas veebilehte.</li>
              <li>Klõpsa brauseri paremas ülanurgas olevatele kolmele punktile (Chrome) või kolmele horisontaalsele joonele (Edge).</li>
              <li>Vali rippmenüüst "Install Unelaegas" või "Install Application".</li>
              <li>Kinnita installimine, klõpsates "Install" nuppu.</li>
              <li>Unelaegas lisatakse nüüd sinu rakenduste loendisse ja saad seda kasutada nagu tavalist rakendust.</li>
            </ol>

            {/* Mozilla Firefox */}
            <h5>Mozilla Firefox:</h5>
            <p>
              Mozilla Firefox ei toeta praegu Progressive Web App (PWA) installimist samamoodi nagu Chrome ja Edge. Soovitame kasutada Chrome või Edge brauserit Unelaegas installimiseks.
            </p>

            {/* Safari (macOS) */}
            <h5>Safari (macOS):</h5>
            <ol>
              <li>Ava Safari brauser.</li>
              <li>Külasta Unelaegas veebilehte.</li>
              <li>Klõpsa brauseri ülaosas menüüribal "File".</li>
              <li>Vali "Add to Dock" või "Add to Home Screen".</li>
              <li>Kinnita installimine ja rakendus lisatakse Docki või Home Screenile.</li>
            </ol>

            <p>
              Kui te ei leia "Install" või "Add to Dock" valikut, veenduge, et teie brauser toetab PWA installimist või kasutage toetatud brauserit. Samuti saate kõiki funktsioone kasutada ilma installeerimata.
            </p>
          </>
        ),
      };
    }
  };

  const { title, body } = getContent();

  // Handle the "Selge" button click
  const handleAgree = () => {
    // Optional: Track event in GA
    ReactGA.event({
      category: 'User',
      action: 'Agree to Install Instructions',
      label: deviceType,
    });

    handleClose(true); // Indicate that the user agreed
  };

  return (
    <Modal
      show={show}
      onHide={() => {}}
      backdrop="static" // Prevent closing by clicking outside
      keyboard={false}  // Prevent closing with the escape key
      centered
    >
      <Modal.Header closeButton={false}> {/* Remove the close button (X) */}
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {/* Only the "Selge" button is present */}
        <Button variant="secondary" className="back-button primary-button-2 align-items-center" onClick={handleAgree}>
          Selge
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

InstructionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
};

export default InstructionsModal;
